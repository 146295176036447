<template>
  <li
    v-if="show"
    data-transaction-name="Brand - Go To Brand Page"
    @click="$emit('go-to')"
  >
    <SfOImage
      class="m-brand-menu-list__image"
      :alt="brand.name"
      :src="urlLogoBrand"
      :enable-events="true"
      @on-error="onError"
    />
  </li>
</template>
<script>
import { getBrandImagePath } from 'theme/helpers'
import SfOImage from 'theme/components/storefront-override/SfOImage.vue';

export default {
  name: 'MRenderImageMenu',
  components: {
    SfOImage
  },
  props: {
    brand: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      show: true
    }
  },
  computed: {
    urlLogoBrand () {
      return getBrandImagePath(this.brand.brand_id)
    }
  },
  methods: {
    onError () {
      this.show = false
    }
  }
}
</script>

<style lang="scss" scoped>
.m-brand-menu-list__image {
  --image-width: auto;
  --image-height: auto;

  ::v-deep {
    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
  }
}
</style>
